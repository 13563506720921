import { v4 as uuidv4 } from 'uuid'
import useResolveValue from '@/utils/resolveValue'
import useToast from '@/utils/toast'
import api from './api'
import state from './state'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    getDetail,
    getTimelineList,
  } = useFetch()

  const {
    resolveDate,
    resolveUtcStartDateTimeTz,
    resolveUtcEndDateTimeTz,
  } = useResolveValue()

  const RECENT_PRODUCT_TEMPLATE = {
    table_name: 'product_info',
    idx_list_text: null,
    start_at: null,
    end_at: null,
    state: 'ACTIVE',
  }

  const getRecentProductTemplate = () => ({
    ...RECENT_PRODUCT_TEMPLATE,
    is_saved: false,
    uuid: uuidv4(),
  })

  const getTitle = recentProduct => {
    const {
      start_at: startAt,
      end_at: endAt,
    } = recentProduct

    const startDate = resolveDate(startAt)
    const endDate = resolveDate(endAt)

    return `[${recentProduct.state}] ${startDate}~${endDate}`
  }

  const addRecentProduct = () => {
    state.content.push(getRecentProductTemplate())
  }

  const getRecentProduct = () => {
    getDetail().then(() => {
      state.content = state.content.map(r => ({
        ...r,
        idx_list_text: r.idx_list.join('\n'),
      }))
    })
  }

  const parseIdxList = b => {
    const idxList = b.idx_list_text.split('\n').map(e => parseInt(e, 10))
    return {
      ...b,
      idx_list: idxList,
    }
  }

  const pick = b => [
    'table_name',
    'idx_list',
    'start_at',
    'end_at',
    'state',
  ].reduce((p, c) => ({
    ...p,
    [c]: b[c],
  }), {})

  const parseDatetime = b => ({
    ...b,
    start_at: resolveUtcStartDateTimeTz(b.start_at),
    end_at: resolveUtcEndDateTimeTz(b.end_at),
  })

  const updateRecentProduct = () => {
    state.isSaving = true
    return api.updateWidget({
      widget_idx: state.idx,
      content: state.content.map(r => parseIdxList(r))
        .map(r => pick(r))
        .map(r => parseDatetime(r)),
    }).then(() => {
      makeToast('primary', '저장에 성공했습니다')
      getRecentProduct()
      getTimelineList()
    }).catch(() => {
      makeToast('primary', '저장에 실패했습니다')
    }).finally(() => {
      state.isSaving = false
    })
  }

  return {
    getTitle,
    addRecentProduct,
    getRecentProduct,
    updateRecentProduct,
  }
}
