<template>
  <TagbyActionButton
    v-if="state.type === 'RECOMMEND_CAMPAIGN'"
    :isSaving="state.isSaving"
    :isValid="true"
    variant="outline-primary"
    text="저장"
    @click="updateRecommendCampaign"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import state from '../../state'
import getters from '../../getters'
import useRecommendCampaign from '../../useRecommendCampaign'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      updateRecommendCampaign,
    } = useRecommendCampaign()

    return {
      state,
      getters,
      updateRecommendCampaign,
    }
  },
})
</script>
