import { v4 as uuidv4 } from 'uuid'
import useToast from '@/utils/toast'
import api from './api'
import state from './state'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    getDetail,
    getTimelineList,
  } = useFetch()

  const COMPT_CURATION_TEMPLATE = {
    table_name: 'campaign_info',
    idx_list_text: null,
    state: 'ACTIVE',
    name: null,
    filter: {
      is_reward: false,
      reward__price: [null, null],
      apply_count: [null, null],
      reversed_registered_at__dday: null,
      selection_at__dday: null,
    },
    sort: [
      '-registered_at',
    ],
  }

  const getComptCurationTemplate = () => ({
    ...COMPT_CURATION_TEMPLATE,
    is_saved: false,
    uuid: uuidv4(),
  })

  const getTitle = curation => `[${curation.state}] ${curation.name}`

  const addComptCuration = () => {
    state.content.push(getComptCurationTemplate())
  }

  const getComptCuration = () => {
    getDetail().then(() => {
      state.content = state.content.map(r => ({
        ...r,
        is_saved: true,
        uuid: r.uuid ?? uuidv4(),
        idx_list_text: r.idx_list?.join('\n'),
        filter: {
          is_recruit: r.filter.is_recruit ?? false,
          reward__price: [r.filter.reward__price__gte, r.filter.reward__price__lte],
          apply_count: [r.filter.apply_count__gte, r.filter.apply_count__lte],
          reversed_registered_at__dday: r.filter.registered_at__dday == null ? null : -r.filter.registered_at__dday,
          selection_at__dday: r.filter.selection_at__dday,
        },
      }))
    })
  }

  const getCleanCuration = b => {
    const cleanData = {}
    cleanData.uuid = b.uuid
    cleanData.name = b.name
    cleanData.state = b.state
    cleanData.table_name = b.table_name
    if (b.idx_list_text != null && b.idx_list_text !== '') {
      cleanData.idx_list = b.idx_list_text
        .split('\n')
        .map(e => parseInt(e, 10))
        .filter(e => Boolean(e)) ?? []
    } else {
      cleanData.idx_list = []
    }
    const filter = {}
    if (b.filter.is_recruit) {
      filter.is_recruit = true
    }
    if (b.filter.reward__price?.[0] != null) {
      filter.reward__price__gte = b.filter.reward__price?.[0]
    }
    if (b.filter.reward__price?.[1] != null) {
      filter.reward__price__lte = b.filter.reward__price?.[1]
    }
    if (b.filter.apply_count?.[0] != null) {
      filter.apply_count__gte = b.filter.apply_count?.[0]
    }
    if (b.filter.apply_count?.[1] != null) {
      filter.apply_count__lte = b.filter.apply_count?.[1]
    }
    if (b.filter.reversed_registered_at__dday != null && b.filter.reversed_registered_at__dday !== '') {
      filter.registered_at__dday = -b.filter.reversed_registered_at__dday
    }
    if (b.filter.selection_at__dday != null && b.filter.selection_at__dday !== '') {
      filter.selection_at__dday = b.filter.selection_at__dday
    }
    cleanData.filter = filter
    cleanData.sort = b.sort
    return cleanData
  }

  const updateComptCuration = () => {
    state.isSaving = true
    return api.updateWidget({
      widget_idx: state.idx,
      content: state.content.map(r => getCleanCuration(r)),
    }).then(() => {
      makeToast('primary', '저장에 성공했습니다')
      getComptCuration()
      getTimelineList()
    }).catch(() => {
      makeToast('primary', '저장에 실패했습니다')
    }).finally(() => {
      state.isSaving = false
    })
  }

  return {
    getTitle,
    addComptCuration,
    getComptCuration,
    updateComptCuration,
  }
}
