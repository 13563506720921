export default () => ({
  hasLoadError: false,
  isVisibleErrorReasonModal: false,
  adminPermission: null,
  idx: null,
  name: null,
  type: null,
  state: 'ACTIVE',
  content: [],
  isSaving: false,
  timelineList: [],
  createMemoSidebar: {
    isVisible: false,
    isSaving: false,
    text: null,
  },
  deleteModal: {
    isVisible: false,
    targetId: null,
  },
  banner: {
    order: 0,
    isUploading: false,
  },
  alert: {
    isUploading: false,
  },
  promotionModal: {
    isUploading: false,
  },
})
