import {
  computed,
} from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'

import api from '../api'
import state from '../state'
import getters from '../getters'
import uploadFile from '@/utils/uploadFile'
import useToast from '@/utils/toast'
import useFetch from '../useFetch'
import useResolveValue from '@/utils/resolveValue'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    resolveUtcStartDateTimeTz,
    resolveUtcEndDateTimeTz,
    resolveDate,
  } = useResolveValue()

  const {
    getDetail,
    getTimelineList,
  } = useFetch()

  const TEMPLATE = {
    landing_path: null,
    state: 'ACTIVE',
    start_at: null,
    end_at: null,
  }

  const getTemplate = () => ({
    ...TEMPLATE,
    is_saved: false,
    uuid: uuidv4(),
  })

  const getTitle = item => {
    const {
      start_at: startAt,
      end_at: endAt,
    } = item

    const startDate = resolveDate(startAt)
    const endDate = resolveDate(endAt)
    return `[${item.state}] ${startDate}~${endDate}`
  }

  const addItem = () => {
    state.content.push(getTemplate())
  }

  const parseItem = item => {
    // parse item
    const temp = null
    return item
  }

  const getWidget = () => {
    getDetail().then(() => {
      state.content = state.content.map(item => parseItem(item))
    })
  }

  const pick = b => [
    'uuid',
    'landing_path',
    'state',
    'start_at',
    'end_at',
  ].reduce((p, c) => ({
    ...p,
    [c]: b[c],
  }), {})

  const parseDatetime = b => ({
    ...b,
    start_at: resolveUtcStartDateTimeTz(b.start_at),
    end_at: resolveUtcEndDateTimeTz(b.end_at),
  })

  const getItem = id => state.content.find(item => item.uuid === id)

  const updateWidget = () => {
    state.isSaving = true
    return api.updateWidget({
      widget_idx: state.idx,
      content: state.content.map(b => pick(b)).map(b => parseDatetime(b)),
    }).then(() => {
      makeToast('primary', '저장에 성공했습니다')
      getWidget()
      getTimelineList()
    }).catch(() => {
      makeToast('primary', '저장에 실패했습니다')
    }).finally(() => {
      state.isSaving = false
    })
  }

  return {
    getTitle,
    addItem,
    getWidget,
    updateWidget,
  }
}
