<template>
  <TagbyActionButton
    v-if="state.type === 'TOP_FIXED_CAMPAIGN'"
    :isSaving="state.isSaving"
    :isValid="true"
    variant="outline-primary"
    text="저장"
    @click="updateFixedCampaign"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import state from '../../state'
import getters from '../../getters'
import useFixedCampaign from '../../useFixedCampaign'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      updateFixedCampaign,
    } = useFixedCampaign()

    return {
      state,
      getters,
      updateFixedCampaign,
    }
  },
})
</script>
