<template>
  <TagbyActionButton
    v-if="state.type === 'LANDING_PATH'"
    :isValid="true"
    variant="outline-primary"
    text="추가"
    @click="addItem"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import state from '../../../state'
import getters from '../../../getters'
import useDefault from '../../../landing-path/useDefault'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      addItem,
    } = useDefault()

    return {
      state,
      getters,
      addItem,
    }
  },
})
</script>
