import {
  computed,
} from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'

import api from './api'
import state from './state'
import getters from './getters'
import uploadFile from '@/utils/uploadFile'
import useToast from '@/utils/toast'
import useFetch from './useFetch'
import useResolveValue from '@/utils/resolveValue'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    resolveUtcStartDateTimeTz,
    resolveUtcEndDateTimeTz,
  } = useResolveValue()

  const {
    getDetail,
    getTimelineList,
  } = useFetch()

  const BANNER_TEMPLATE = {
    header_1: '',
    header_2: null,
    img_idx: null,
    img_url: null,
    redirect_path: null,
    redirect_option: null,
    option_text: null,
    state: 'ACTIVE',
    start_at: null,
    end_at: null,
  }

  const getBannerTemplate = () => ({
    ...BANNER_TEMPLATE,
    is_saved: false,
    uuid: uuidv4(),
  })

  const pick = b => Object.keys(BANNER_TEMPLATE).reduce((p, c) => ({
    ...p,
    [c]: b[c],
  }), {})

  const parseDatetime = b => ({
    ...b,
    start_at: resolveUtcStartDateTimeTz(b.start_at),
    end_at: resolveUtcEndDateTimeTz(b.end_at),
  })

  const banner = computed(() => state.content[state.banner.order])

  const inputImgFile = file => {
    if (!file) {
      return
    }
    state.banner.isUploading = true
    uploadFile(file, 'app_widget_info', '').then(response => {
      banner.value.img_url = response.file_url
      banner.value.img_idx = response.idx
    }).catch(() => {
      makeToast('danger', '파일 업로드에 실패했습니다')
    }).finally(() => {
      state.banner.isUploading = false
    })
  }

  const hasNewBanner = computed(() => state.content.find(b => !b.is_saved))

  const createNewBanner = () => {
    state.content = [
      ...state.content.slice(0, state.banner.order),
      getBannerTemplate(),
      ...state.content.slice(state.banner.order),
    ]
  }

  const isFirstOrder = computed(() => state.banner.order === 0)

  const isLastOrder = computed(() => {
    const limit = state.content.length - 1
    return state.banner.order === limit
  })

  const moveNextBanner = () => {
    if (isLastOrder.value) {
      return
    }
    const currentBanner = state.content[state.banner.order]
    const nextBanner = state.content[state.banner.order + 1]
    const temp = state.content
    temp[state.banner.order] = nextBanner
    temp[state.banner.order + 1] = currentBanner
    state.content = []
    state.content = temp
  }

  const movePrevBanner = () => {
    if (isFirstOrder.value) {
      return
    }
    const currentBanner = state.content[state.banner.order]
    const prevBanner = state.content[state.banner.order - 1]
    const temp = state.content
    temp[state.banner.order] = prevBanner
    temp[state.banner.order - 1] = currentBanner
    state.content = []
    state.content = temp
  }

  const getBanner = () => {
    getDetail().then(() => {
      if (!state.content?.length) {
        state.content = [getBannerTemplate()]
      } else {
        state.content = state.content.map(b => ({
          ...b,
          is_saved: true,
          uuid: uuidv4(),
        }))
      }
    })
  }

  const updateBanner = () => {
    state.isSaving = true
    return api.updateWidget({
      widget_idx: state.idx,
      content: state.content.map(b => pick(b)).map(b => parseDatetime(b)),
    }).then(() => {
      makeToast('primary', '저장에 성공했습니다')
      getBanner()
      getTimelineList()
    }).catch(() => {
      makeToast('danger', '저장에 실패했습니다')
    }).finally(() => {
      state.isSaving = false
    })
  }

  const inputRedirectPath = () => {
    banner.value.option_text = null
  }

  return {
    banner,
    inputImgFile,
    hasNewBanner,
    createNewBanner,
    moveNextBanner,
    movePrevBanner,
    isLastOrder,
    isFirstOrder,
    getBanner,
    updateBanner,
    inputRedirectPath,
  }
}
