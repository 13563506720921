<template>
  <TagbyActionButton
    v-if="state.type === 'COMPT_CURATION'"
    :isSaving="state.isSaving"
    :isValid="true"
    variant="outline-primary"
    text="저장"
    @click="updateComptCuration"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import state from '../../state'
import getters from '../../getters'
import useComptCuration from '../../useComptCuration'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      updateComptCuration,
    } = useComptCuration()

    return {
      state,
      getters,
      updateComptCuration,
    }
  },
})
</script>
