import useToast from '@/utils/toast'
import state from './state'
import getters from './getters'
import api from './api'
import useErrorReason from './useErroReason'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
    turnOffErrorReasonModal,
  } = useErrorReason()

  const getAdminMe = () => api.getAdminMe().then(response => {
    state.adminPermission = response.data.data.permission
  })

  const getDetail = () => api.getDetail({
    idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.name = resData.name
    state.state = resData.state
    state.content = resData.content
  }).catch(err => {
    state.hasLoadError = true
    turnOnErrorReasonModal(err)
  })

  const getTimelineList = () => api.getTimelineList({
    info_table: 'app_widget_info',
    info_idx: state.idx,
  }).then(response => {
    state.timelineList = response.data.data
  }).catch(err => {
    makeToast('danger', '타임라인을 가져오는데 실패했습니다')
    turnOnErrorReasonModal(err)
  })

  return {
    getAdminMe,
    getDetail,
    turnOffErrorReasonModal,
    getTimelineList,
  }
}
