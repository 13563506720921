<template>
  <TagbyActionCard>
    <template #left>
      <UpdateBannerButton />
      <AddRecentProductButton />
      <UpdateRecentProductButton />
      <AddFixedCampaignButton />
      <UpdateFixedCampaignButton />
      <AddRecommendCampaignButton />
      <UpdateRecommendCampaignButton />
      <AddComptCurationButton />
      <UpdateComptCurationButton />
      <AddAlertButton />
      <UpdateAlertButton />
      <AddPromotionModalButton />
      <UpdatePromotionModalButton />
      <AddLandingPathButton />
      <UpdateLandingPathButton />
    </template>

    <template #right>
      <CreateMemoButton />
    </template>
  </TagbyActionCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionCard from '@/components/TagbyActionCard.vue'
import UpdateBannerButton from './UpdateBannerButton.vue'
import CreateMemoButton from './CreateMemoButton.vue'
import AddRecentProductButton from './AddRecentProductButton.vue'
import UpdateRecentProductButton from './UpdateRecentProductButton.vue'
import AddFixedCampaignButton from './AddFixedCampaignButton.vue'
import UpdateFixedCampaignButton from './UpdateFixedCampaignButton.vue'
import AddRecommendCampaignButton from './AddRecommendCampaignButton.vue'
import UpdateRecommendCampaignButton from './UpdateRecommendCampaignButton.vue'
import AddComptCurationButton from './AddComptCurationButton.vue'
import UpdateComptCurationButton from './UpdateComptCurationButton.vue'
import AddAlertButton from './AddAlertButton.vue'
import UpdateAlertButton from './UpdateAlertButton.vue'
import AddPromotionModalButton from './promotion-modal/AddButton.vue'
import UpdatePromotionModalButton from './promotion-modal/UpdateButton.vue'
import AddLandingPathButton from './landing-path/AddButton.vue'
import UpdateLandingPathButton from './landing-path/UpdateButton.vue'

export default defineComponent({
  components: {
    TagbyActionCard,
    CreateMemoButton,
    UpdateBannerButton,
    AddRecentProductButton,
    UpdateRecentProductButton,
    AddFixedCampaignButton,
    UpdateFixedCampaignButton,
    AddRecommendCampaignButton,
    UpdateRecommendCampaignButton,
    AddComptCurationButton,
    UpdateComptCurationButton,
    AddAlertButton,
    UpdateAlertButton,
    AddPromotionModalButton,
    UpdatePromotionModalButton,
    AddLandingPathButton,
    UpdateLandingPathButton,
  },
  setup() {

  },
})
</script>
