<template>
  <BCard>
    <AppCollapse>
      <AppCollapseItem
        v-for="item in state.content"
        :key="item.id"
        :title="getTitle(item)"
      >
        <BRow>
          <BCol
            cols="12"
            sm="6"
            md="4"
          >
            <BFormGroup
              label="LANDING_PATH"
            >
              <TagbySelect
                v-model="item.landing_path"
                :reduce="opt => opt.value"
                :options="landingOptions"
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="6"
            md="4"
          >
            <BFormGroup
              label="STATE"
            >
              <TagbySelect
                v-model="item.state"
                :options="stateOptions"
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="6"
            md="4"
          >
            <BFormGroup
              label="START_AT"
            >
              <TagbyDatetimeInputV3 v-model="item.start_at" />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="6"
            md="4"
          >
            <BFormGroup
              label="END_AT"
            >
              <TagbyDatetimeInputV3 v-model="item.end_at" />
            </BFormGroup>
          </BCol>
        </BRow>

        <BRow class="d-flex justify-content-end">
          <BButton
            class="mr-1"
            variant="outline-danger"
            @click="turnOnDeleteModal(item.uuid)"
          >
            삭제
          </BButton>
        </BRow>
      </AppCollapseItem>
    </AppCollapse>

  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormCheckbox,
  BImg,
  BFormFile,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import TagbyDatetimeInputV3 from '@/components/TagbyDatetimeInputV3.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import state from '../../state'
import getters from '../../getters'
import useDefault from '../../promotion-modal/useDefault'
import useDeleteModal from '../../useDeleteModal'
import TagbyRangeInputV2 from '@/components/TagbyRangeInputV2.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    BImg,
    BFormFile,
    TagbyDatetimeInputV3,
    TagbySelect,
    TagbyRangeInputV2,
    AppCollapse,
    AppCollapseItem,
  },
  setup() {
    const stateOptions = [
      'ACTIVE',
      'INACTIVE',
    ]

    const {
      getTitle,
    } = useDefault()

    const {
      turnOnModal: turnOnDeleteModal,
    } = useDeleteModal()

    const landingOptions = [
      { label: '홈', value: '/main/home' },
      { label: '탐색', value: '/main/browse' },
      { label: '마켓', value: '/main/market' },
      { label: '알림', value: '/main/alarm' },
    ]

    return {
      state,
      getters,
      stateOptions,
      getTitle,
      turnOnDeleteModal,
      landingOptions,
    }
  },
})
</script>
