<template>
  <BCard>
    <BRow>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="IDX">
          <BFormInput
            v-model="state.idx"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="TYPE">
          <BFormInput
            v-model="state.type"
            readonly
          />
        </BFormGroup>
      </BCol>
      <!-- <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="STATE">
          <TagbySelect
            v-model="state.state"
            :options="stateOptions"
          />
        </BFormGroup>
      </BCol> -->
      <!-- <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="CREATED AT">
          <TagbyDatetimeInputV3
            v-model="state.createdAt"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i'
            }"
            disabled
          />
        </BFormGroup>
      </BCol> -->
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormCheckbox,
} from 'bootstrap-vue'
import TagbyDatetimeInputV3 from '@/components/TagbyDatetimeInputV3.vue'
import state from '../../state'
import getters from '../../getters'
import TagbySelect from '@/components/TagbySelect.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    TagbyDatetimeInputV3,
    TagbySelect,
    BFormCheckbox,
  },
  setup() {
    const stateOptions = [
      'ACTIVE',
      'INACTIVE',
    ]
    return {
      state,
      getters,
      stateOptions,
    }
  },
})
</script>
