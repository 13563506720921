<template>
  <TagbyActionButton
    v-if="state.type === 'HOME_RECENT_PRODUCT'"
    :isSaving="state.isSaving"
    :isValid="true"
    variant="outline-primary"
    text="저장"
    @click="updateRecentProduct"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import state from '../../state'
import getters from '../../getters'
import useRecentProduct from '../../useRecentProduct'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      updateRecentProduct,
    } = useRecentProduct()

    return {
      state,
      getters,
      updateRecentProduct,
    }
  },
})
</script>
